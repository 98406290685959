import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, catchError, map, Observable } from 'rxjs'
import { environment } from '../../../environments/environment'
import { Response } from 'src/app/interface/response'
import { Authn, Permission } from 'src/app/shared/models/authn.interface'

interface loginInfo {
  email: string,
  password: string
}

@Injectable({ providedIn: `root` })
export class AuthService {
  private baseUrl: string = environment.baseUrl

  public permissions = new BehaviorSubject<string[]>([])

  public token = new BehaviorSubject<string>(undefined)

  constructor (private http: HttpClient) {
    this.token.next(localStorage.getItem(`token`))
  }

  public login (loginInfo: loginInfo): Observable<string> {
    return this.http.put<Response<string>>(
      `${this.baseUrl}${environment.authn}`,
      loginInfo).pipe(
      map(response => {
        const data = this.errorOrData(response)
        this.token.next(data)
        localStorage.setItem(`token`, data)
        return data
      }))
  }

  public getPermissions (): Observable<string[]> {
    const url = `${environment.baseUrl}/authn/me`
    return this.http.get<Response<Authn>>(url).pipe(
      map(response => {
        const permissions = this.errorOrData(response)?.roles
          .reduce((acc: Permission[], el) =>
            [...acc, ...el.permissions], [])
          .filter(e => e.app === `app-public` && e.active)
          .map(p => p.label)
        this.permissions.next(permissions)
        return permissions
      }),
      catchError((error) => {
        const message = error.error.message || error.error
        if (message.startsWith(`PERMISSION.DENIED`)) {
          throw new Error(`ERROR.PERMISSION_DENIED`)
        }
        throw new Error(error.error.message)
      }))
  }

  public logout () {
    this.token.next(undefined)
    localStorage.removeItem(`token`)
  }

  private errorOrData<T> ({ message, data }: Response<T>): T {
    if (message !== `OK` || !data) {
      throw new Error(message)
    }
    return data
  }
}
