import { Component } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { HeaderService } from './shared/services/header.service'

@Component({
  selector: `eld-root`,
  styleUrls: [`./app.component.scss`],
  templateUrl: `./app.component.html`
})
export class AppComponent {
  public langs = [{
    name: `Deutsch`,
    value: `de`
  }, {
    name: `English`,
    value: `en`
  }]

  public language = `de`

  constructor (
    public headerService: HeaderService,
    public translate: TranslateService
  ) {
    translate.setDefaultLang(this.language)

    const lang = localStorage.getItem(`lang`) || translate.getBrowserLang()

    if (lang && this.langs.map(({ value }) => value).includes(lang)) {
      this.language = lang
      translate.use(lang)
    }
    localStorage.setItem(`lang`, lang || this.language)
  }
}
