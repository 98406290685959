<div class="header container-fluid">
  <div class="row">
    <div class="col-auto  col-md-2 p-0 col-xl-1 d-flex align-items-center">
      <ng-container *ngIf="headerService.button | async as button">
        <button class="px-2 btn btn-link text-dark no-underline" (click)="button.callback()">
          <fa-icon [icon]="button.icon" class="me-2"></fa-icon>
          <span class="d-none d-sm-inline">{{ 'BUTTON.' + button.label | translate }}</span>
        </button>
      </ng-container>
    </div>
    <div class="col p-0">
      <div class="item d-flex">
        <img class="logo-responsive" src="assets/imgs/logo/eloaded_logo.svg">
      </div>
    </div>
    <div class="col-5 col-md-2 col-xl-1 d-flex align-items-center">
      <select class="form-select" [(ngModel)]="language" (change)="translate.use(language)">
        <ng-container *ngFor="let lang of langs">
          <option [value]="lang.value">{{ lang.name }}</option>
        </ng-container>
      </select>
    </div>
  </div>
</div>
<div class="content container-fluid overflow-y-scroll">
  <router-outlet></router-outlet>
</div>