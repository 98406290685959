<svg class="progress-ring" [attr.width]="style?.size" [attr.height]="style?.size">
  <g class="progress-ring-cont" *ngIf="animation && !processSpinner">
    <circle
      class="pulsating-circle"
      stroke="#c9c9cf"
      stroke-linecap="round"
      [attr.stroke-dasharray]="circ + ' ' + circ"
      [attr.stroke-width]="style?.strokeWidth"
      fill="transparent"
      [attr.r]="r"
      [attr.cx]="(style?.size || 0) / 2"
      [attr.cy]="(style?.size || 0) / 2"/>
  </g>
  <g class="progress-ring-cont">
    <circle
      stroke="#c9c9cf"
      stroke-linecap="round"
      [attr.stroke-dasharray]="circ + ' ' + circ"
      [attr.stroke-width]="style?.strokeWidth"
      fill="transparent"
      [attr.r]="r"
      [attr.cx]="(style?.size || 0) / 2"
      [attr.cy]="(style?.size || 0) / 2"/>
  </g>
  <g class="progress-ring-cont" *ngIf="!processSpinner">
    <circle
      stroke="#60606c"
      stroke-linecap="round"
      [attr.stroke-dasharray]="circ + ' ' + circ"
      [attr.stroke-dashoffset]="autoStop ? offset(autoStop) : undefined"
      [attr.stroke-width]="style?.strokeWidth"
      fill="transparent"
      [attr.r]="r"
      [attr.cx]="(style?.size || 0) / 2"
      [attr.cy]="(style?.size || 0) / 2"/>
  </g>
  <g class="progress-ring-cont" *ngIf="!processSpinner">
    <circle
      stroke="#8fbe43"
      stroke-linecap="round"
      [attr.stroke-dasharray]="circ + ' ' + circ"
      [attr.stroke-dashoffset]="soc ? offset(soc) : undefined"
      [attr.stroke-width]="style?.strokeWidth"
      fill="transparent"
      [attr.r]="r"
      [attr.cx]="(style?.size || 0) / 2"
      [attr.cy]="(style?.size || 0) / 2"/>
  </g>
  <g class="progress-ring-cont" *ngIf="processSpinner">
    <circle
      class="process-spinner"
      stroke="#8fbe43"
      stroke-linecap="round"
      [attr.stroke-dasharray]="circ + ' ' + circ"
      [attr.stroke-dashoffset]="soc ? offset(soc) : undefined"
      [attr.stroke-width]="style?.strokeWidth"
      fill="transparent"
      [attr.r]="r"
      [attr.cx]="(style?.size || 0) / 2"
      [attr.cy]="(style?.size || 0) / 2"/>
  </g>
  <text x="50%" y="45%" text-anchor="middle" *ngIf="style?.showText">
    <tspan *ngIf="soc" class="circletext_soc" x="50%" fill="#463f3a" stroke-width="2px" font-size="4em">{{ soc }} %</tspan>
    <tspan *ngIf="autoStop" class="circletext_rest" x="50%" fill="#463f3a" stroke-width="2px" dy="1em" font-size="1.65em">{{ 'T_SOC' | translate }} {{ autoStop }} %</tspan>
    <tspan *ngIf="remainingTime" class="circletext_rest" x="50%" fill="#463f3a" stroke-width="2px" dy="1em" font-size="1.65em">{{ 'ECT' | translate }} {{ remainingTime | secondsToTime }}</tspan>
    <tspan *ngIf="elapsedTime" class="circletext_rest" x="50%" fill="#463f3a" stroke-width="2px" dy="1em" font-size="1.65em">{{ 'CT' | translate }} {{ elapsedTime | secondsToTime }}</tspan>
  </text>
</svg>