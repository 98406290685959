import { Component, Input } from '@angular/core'

export interface ProgressCircleStyle {
  size: number;
  strokeWidth: number;
  showText: boolean;
}

@Component({
  selector: `eld-progress-circle`,
  styleUrls: [`./progress-circle.component.scss`],
  templateUrl: `./progress-circle.component.html`
})
export class ProgressCircleComponent {
  @Input() public style!: ProgressCircleStyle

  @Input() public autoStop?: number

  @Input() public soc?: number

  @Input() public remainingTime?: number

  @Input() public elapsedTime?: number

  @Input() public animation?: boolean = true

  @Input() public processSpinner?: boolean = false

  public pi = Math.PI

  public get r (): number {
    return ((this.style?.size || 0) / 2) - this.style?.strokeWidth
  }

  public get circ (): number {
    return this.r * 2 * Math.PI
  }

  public offset (perc: number): number {
    return this.circ - perc / 100 * this.circ
  }

}
