import { Pipe, PipeTransform } from '@angular/core'

/**
  * @ngdoc pipe
  * @name SecondsToTimePipe
  * @desc Transform given seconds in readable string
  */
@Pipe({ name: `secondsToTime` })
export class SecondsToTimePipe implements PipeTransform {
  public times: { [key: string]: number } = {
    day: 86400,
    hour: 3600,
    minute: 60,
    month: 2629746,
    second: 1,
    year: 31557600
  }

  /**
    * @function
    * @name transform
    * @desc Transform given seconds in readable string
    * @param { number } seconds - Time in seconds
    * @returns { string } - Formatted string
    */
  public transform (seconds: number): string {
    let counter = 0
    let timeString = ``
    const conversion = (key: string) => Math.floor(seconds / this.times[key])

    for (const key in this.times) {
      const conv = conversion(key)
      if (conv > 0 && (key !== `second` || !timeString.length) && (counter < 2)) {
        counter++
        let timeUnit = ``
        switch (`${key}`) {
          case `second`: timeUnit = `s`; break
          case `minute`: timeUnit = `min`; break
          case `hour`: timeUnit = `h`; break
          case `day`: timeUnit = `d`; break
          case `year`: timeUnit = `y`
        }
        timeString += `${conv} ${timeUnit} `
        seconds = seconds - this.times[key] * conv

        if (key === `second` && counter === 1) {
          timeString = `< 1 min`
        }
      }
    }

    return timeString.trim()
  }
}
